import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Empowering Communities Beyond Connectivity  💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Empowering Communities Beyond Connectivity </h1>
        
          <StaticImage
          layout="constrained"
          src="images/donation-image.png"
          alt="Purple Cow Internet gives back to the community"
          /> 
          <p>At Purple Cow Internet, our commitment runs deeper than just providing unparalleled internet services. We believe that empowerment extends to uplifting and enriching our broader community. Our guiding philosophy is profound in its simplicity: to attain our desires in this world, we first ensure that others achieve theirs.</p>

          <p>One shining beacon of our commitment to community enrichment is our support for <a href="www.weareyoung.ca">We Are Young</a>  (WAY). A commendable charitable initiative, WAY is on a mission to grant unfulfilled wishes to seniors, not just in the Atlantic Provinces, but now also reaching hearts in Ontario through their pilot program. Their objective? To change societal perceptions and values towards our eldest generation, fostering respect, appreciation, and an inclusive environment where seniors can truly belong.</p>

          <p>Purple Cow Internet is deeply honored to partner with this transformative initiative. For every customer that chooses us, we match every $10 donation made at checkout towards WAY. This collaboration is not just about funds – it's about making a genuine difference. Last year alone, with the unwavering support of our patrons, we proudly contributed over $10,000 to WAY, channeling our collective efforts to drive meaningful impact.</p>

          <p>By aligning with Purple Cow Internet, you're not just choosing exceptional connectivity; you're supporting a movement that reverberates positivity and change. Together, let's continue weaving a fabric of community where every thread, young or old, is valued and cherished.</p>

          <p>I hope this encapsulates your association with <a href="www.weareyoung.ca">"We Are Young"</a>  and the positive influence Purple Cow Internet is creating. Cheers to more impactful collaborations!</p>

         <p>
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
